<template>
<v-layout>
        <v-autocomplete v-if="!readOnlyAutocomplete"
          v-model="address"
          :no-filter="true"
          @change="changedValue"
          outlined
          :disabled="isAutoCompleteDisabled"
          :items="entries"
          :loading="isLoading"
          :search-input.sync="search"
          placeholder="Address"
          return-object
        >
        </v-autocomplete>
        <v-layout v-if="readOnlyAutocomplete">
        <span
         class="readonly-text-view">
          {{(isDropOff == false)
          ?this.$store.state.appointment.appointment.address.location.address
          :this.$store.state.appointment.appointment.address.dropOff.address}}
        </span>
        <i class="mdi mdi-square-edit-outline u-green-text mdi-24px"
         style="cursor:pointer" v-if="readOnlyAutocomplete"
          @click="setReadOnly(false)"></i>
        </v-layout>
</v-layout>
</template>

<script>
import SessionStorageHelper from '@/store/helper/SessionStorageHelper';
import EventHelper from '@/event-helper';
import httpClient from '../../api/config/axiosConfig';
import webConstant from '../../api/config/webConstant';
import DropoffUserIdModel from '../../store/model/DropoffUserIdModel';

export default {
  name: 'LocationAutoComplete',
  props: {
    isDropOff: Boolean,
    readOnly: Boolean,
    isDisabled: Boolean,
    isNotTowing: Boolean,
  },
  data: () => ({
    entries: [],
    search: null,
    address: '',
    isLoading: false,
    readOnlyAutocomplete: false,
  }),
  async created() {
    const mobileData = SessionStorageHelper.getSessionData('mobile-service');
    if (this.isNotTowing) {
      // this.address = { description: mobileData.step2.address.location, id: '000' };
      // this.address = ;
      if (JSON.stringify(mobileData.step2.address.location) !== '') {
        await this.loadAddressSaved(mobileData.step2.address.location);
        EventHelper.$emit('appointmentAddressChanged', mobileData.step2.address.address);
      }
    } else {
      const towing = SessionStorageHelper.getSessionData('towing-service');
      if (this.isDropOff) {
        this.loadAddressSaved(towing.step1.address.dropOffLatLon.location);
      } else {
        this.loadAddressSaved(towing.step1.address.locationLatLon.location);
      }
    }
    this.readOnlyAutocomplete = this.readOnly;
  },
  methods: {
    async changedValue(value) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let serviceCategoryId = '';
      if (this.$route.name === 'secondStep') {
        const dat = SessionStorageHelper.getSessionData('mobile-service');
        dat.step2.address.location = value;
        SessionStorageHelper.saveData('mobile-service', dat);
        serviceCategoryId = dat.step1.service.serviceCategoryId;
      } else if (this.$route.name === 'towingFirstStep') {
        const towingData = SessionStorageHelper.getSessionData('towing-service');
        if (!this.isDropOff && towingData !== undefined) {
          towingData.step1.address.locationLatLon.location = value;
          SessionStorageHelper.saveData('towing-service', towingData);
        } else if (towingData !== undefined) {
          towingData.step1.address.dropOffLatLon.location = value;
          SessionStorageHelper.saveData('towing-service', towingData);
        }
        if (towingData !== undefined) {
          serviceCategoryId = towingData.step1.serviceCategoryId;
        }
      } else {
        serviceCategoryId = this.$store.state.serviceCategory.serviceSelected.serviceCategoryId;
      }

      if (!this.isDropOff || this.isDropOff === 'false') {
        await this.$store.dispatch('appointment/changeAddressLocation', value);
        await this.$store.dispatch('appointment/changeIsNearbyLocation', false);
        if (this.isNotTowing) {
          // const response = await this.$store.dispatch('appointment/getAvailableSlots', serviceCategoryId);
          // localStorage.setItem('availableSlots', JSON.stringify(response.availableDates));
        } else if (this.$store.state.appointment.appointment.address.dropOff.address !== '') {
          await this.$store.dispatch('appointment/changeDropOffLocation',
            this.$store.state.appointment.appointment.address.dropOff.address);
        }
        // this.$store.dispatch('appointment/changeAddressLocation', value.description).then(() => {
        //   this.$store.dispatch('appointment/changeIsNearbyLocation', false);
        //   if (this.isNotTowing) {
        //     this.$store.dispatch('appointment/getAvailableSlots', serviceCategoryId)
        //       .then((response) => {
        //         localStorage.setItem('availableSlots', JSON.stringify(response.availableDates));
        //       });
        //   } else if (this.$store.state.appointment.appointment.address.dropOff.address !== '') {
        //     this.$store.dispatch('appointment/changeDropOffLocation',
        //       this.$store.state.appointment.appointment.address.dropOff.address);
        //   }
        // });
        // EventHelper.$emit('appointmentAddressChanged', value);
      } else {
        const dropoffValue = new DropoffUserIdModel(Number(localStorage.getItem('sessionId')), value);
        this.$store.dispatch('appointment/changeDropOffLocation', dropoffValue);
        const response = await this.$store.dispatch('appointment/getAvailableSlots', '4');
        localStorage.setItem('availableSlots', JSON.stringify(response.availableDates));// serviceCategoryId = 4 is for towing service
      }
    },
    async loadAddressSaved(value) {
      if (this.isNotTowing) {
        if (value !== '') {
          this.$store.dispatch('appointment/changeAddressLocation', value).then(() => {
            this.$store.dispatch('appointment/changeIsNearbyLocation', false).then();
            // if (this.isNotTowing) {
            //   // this.$store.dispatch('appointment/getAvailableSlots', mobileData.step1.service.serviceCategoryId)
            //   //   .then((response) => {
            //   //     localStorage.setItem('availableSlots', JSON.stringify(response.availableDates));
            //   //   });
            // }
          });
        }
      } else if (!this.isDropOff || this.isDropOff === 'false') {
        if (value !== '') {
          this.$store.dispatch('appointment/changeAddressLocation', value).then(() => {
            this.$store.dispatch('appointment/changeIsNearbyLocation', false);
            // this.$store.dispatch('appointment/getAvailableSlots', towingSession.step1.serviceCategoryId)
            //   .then((response) => {
            //     localStorage.setItem('availableSlots', JSON.stringify(response.availableDates));
            //   });
          });
        }
      } else if (value !== '') {
        this.$store.dispatch('appointment/changeDropOffLocation', new DropoffUserIdModel(Number(localStorage.getItem('sessionId')), value)).then(() => {
          this.$store.dispatch('appointment/changeIsNearbyLocation', false);
          // this.$store.dispatch('appointment/getAvailableSlots', towingSession.step1.serviceCategoryId)
          //   .then((response) => {
          //     localStorage.setItem('availableSlots', JSON.stringify(response.availableDates));
          //   });
        });
      }
    },
    setReadOnly(readOnly) {
      this.readOnlyAutocomplete = readOnly;
      this.changedValue('');
      this.$store.dispatch('appointment/saveDateTimeString', '');
    },
  },
  computed: {
    isAutoCompleteDisabled() {
      return this.isDisabled
          && this.$store.state.appointment.appointment.address.isNearByLocation !== ''
          && !this.isDropOff;
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      if (val !== '') {
        httpClient.get(`/1.0/${webConstant.GET_AUTOFILL_ADDRESS}/${val}`)
          .then((resp) => {
            const { count, predictions } = resp.data.payload;
            this.count = count;
            this.entries = predictions.map((x) => x.description);
            this.isLoading = false;
          }).catch(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    isDisabled(val) {
      if (val
        && localStorage.getItem('nearbylocationaddress') !== ''
        && localStorage.getItem('nearbylocationaddress') !== null) {
        this.address = '';
        this.search = '';
      }
    },
  },
};
</script>
